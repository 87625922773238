import swiper from "swiper";

//サムネイル
var sliderThumbnail = new swiper('.thumbnail', {
  slidesPerView: 3,
  freeMode: false,
  observer: true,
  allowTouchMove: false,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  spaceBetween: 32,
  breakpoints: {
    768: {
      spaceBetween: 10,
    }
  },
});

//スライダー
var slider = new swiper('.slider', {
  slidesPerView: "auto",
  freeMode: false,
  observer: false,
  allowTouchMove: false,
  effect: "fade",
  // autoHeight: true,
  thumbs: {
    swiper: sliderThumbnail
  }
});